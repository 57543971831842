const contact = {
  phoneNumber: "+61730253000",
  phoneNumberDisplay: "07 3025 3000",
  address: "95 North Quay\nBrisbane City QLD 4000",
  email: "info@headmarkconsulting.com",
  coordinates: { lat: -27.469606, lng: 153.020877 },
  linkedIn: "https://www.linkedin.com/company/headmarkconsulting",
  sharepoint: "https://headmarkconsultingcom.sharepoint.com/sites/all",
}

export default contact
