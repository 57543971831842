import React from "react"
import contact from "../support/contact"
import pagePaths from "../support/pagePaths"
import { Link } from "gatsby"

const Footer = () => (
  <footer
    className="h3-footer bg-with-dark-black"
    style={{
      backgroundImage: "url(image/denise-jans-GRW8Yprjgho-unsplash.jpg)",
    }}
  >
    <div className="footer-top-area">
      <div className="container">
        <div className="row">
          <div className="d-none d-sm-block col-lg-4 col-md-6 col-sm-6 col-12">
            <div className="about-h3aw footer-widget">
              <h4 className="title">About Company</h4>
              <p className="text">
                Headmark Consulting is a specialised consulting company
                primarily focused on providing Subject Matter Experts with
                Information Domain expertise. Our team also provide more broad
                Defence and Industry experience and skill in Business and
                Operations Analysis.
              </p>
              <Link className="more" to="/about">
                Read More{" "}
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              </Link>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="cat-h3aw footer-widget">
              <h4 className="title">Navigation</h4>
              <ul className="cat-list">
                {Object.entries(pagePaths).map(([name, path]) => (
                  <li key={path}>
                    <Link to={`/${path}`}>
                      <span>
                        <i className="fa fa-long-arrow-alt-right"></i>
                      </span>
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-6 col-12"></div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-12">
            <div className="contact-h3aw footer-widget">
              <h4 className="title">Contact Information</h4>
              <ul className="c-info">
                <li>
                  <a href={`mailto:${contact.email}`}>
                    <span>
                      <i className="fa fa-envelope"></i>
                    </span>
                    {contact.email}
                  </a>
                </li>
                <li>
                  <a href={`tel:${contact.phoneNumber}`}>
                    <span>
                      <i className="fa fa-phone"></i>
                    </span>
                    {contact.phoneNumberDisplay}
                  </a>
                </li>
                <li>
                  <Link to="/contact">
                    <span>
                      <i className="fa fa-map-marker"></i>
                    </span>
                    {contact.address.split("\n").map((line, i) => (
                      <div key={i}>{line}</div>
                    ))}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="footer-bottom-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-7 col-sm-12 col-12">
            <div className="h3fb-left">
              <p>Headmark Consulting Pty Ltd © 2020. All Rights Reserved.</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 col-sm-12 col-12">
            <div className="h3fb-social">
              <ul>
                <li>
                  <a href={contact.linkedIn} target="_blank" rel="nofollow">
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
