const paths: Record<string, string> = {
  Home: "",
  "Contact Us": "contact",
  "About Us": "about",
  Services: "services",
  Legal: "legal",
  "Privacy Policy": "legal#PrivacyPolicy",
}

export default paths
